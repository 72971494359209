<template>
  <div class="footerSpace_">
    <b-row class="fotterLinkSpace">
      <b-col lg="4">
        <div class="headerText">
          アカウント
        </div>
        <footer-link
          label="新規登録"
          link-to=""
        />
        <footer-link
          label="ログイン"
          link-to=""
        />
      </b-col>
      <b-col lg="4">
        <div class="headerText">
          初めてガイド
        </div>
        <footer-link
          label="初めての方へ"
          link-to=""
        />
        <footer-link
          label="サービスの紹介"
          link-to=""
        />
        <footer-link
          label="よくある質問"
          link-to="faq"
        />
        <footer-link
          label="ご利用ガイド"
          link-to=""
        />
      </b-col>
      <b-col lg="4">
        <div class="headerText">
          会社情報
        </div>
        <footer-link
          label="企業情報"
          link-to=""
        />
        <footer-link
          label="お問い合わせ"
          link-to=""
        />
      </b-col>
    </b-row>
    <div class="termsSpace_">
      <b-row>
        <b-col lg="4">
          <footer-link
            label="特定商取引法に基づく表記"
            link-to="commercial-law"
          />
        </b-col>
        <b-col lg="4">
          <footer-link
            label="プライバシーポリシー"
            link-to="privacy"
          />
        </b-col>
        <b-col lg="4">
          <footer-link
            label="利用規約"
            link-to="student-terms"
          />
        </b-col>
      </b-row>
    </div>
    <div class="">
      <p class="copyrightText text-center">
        <span class="">
          COPYRIGHT © {{ new Date().getFullYear() }}
          <span class="">touch family care, All rights Reserved</span>
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import FooterLink from '@/components/basic/footer/FooterLink.vue'

export default {
  components: {
    BRow,
    BCol,
    FooterLink,
  },
  data() {
    return {
    }
  },
}
</script>
<style scoped>
.footerSpace_ {
  background-color: rgb(46, 45, 45);
  /* height: 250px; */
  margin: 0 -5%;
}
.fotterLinkSpace {
  margin: 2% 0 1% 7%;
  width: 80%;
}
.linkText {
  color: rgb(190, 190, 190);
  padding: 5%;
}
a {
  text-decoration: none;
}
.headerText {
  font-size: 1.1rem;
  color: rgb(107, 106, 106);
  margin: 3% 30%;
  /* padding: 5%; */
}
.copyrightText {
  color: rgb(107, 106, 106);
}
.termsSpace_ {
  width: 70%;
  margin: 0 auto;
}
</style>
