<template>
  <div class="">
    <a
      @click="move"
    >
      <div class="linkText">
        {{ label }}
      </div>
    </a>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    linkTo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    move() {
      const currentPath = this.$route.path.split('/')
      if (this.linkTo === '') return
      if (this.linkTo === currentPath[1]) return
      this.$router.push({ name: this.linkTo })
    },
  },
}
</script>
<style scoped>
.linkText {
  color: rgb(190, 190, 190);
  margin:  5% 0 5% 30%;
  /* padding: 5%; */
}
a {
  text-decoration: none;
}
</style>
