<template>
  <div>
    <b-img
      src="https://firebasestorage.googleapis.com/v0/b/jp-career.appspot.com/o/articles%2Figor-son-Nod1nQ0i5a0-unsplash.jpg?alt=media&token=7f552e20-d54c-4518-af56-52604ac0a234"
      blank-color="#ccc"
      alt="placeholder"
      class="imgStyle_"
    />
    <div class="title_ text-center mt-3">
      プライバシーポリシー
    </div>
    <div class="termsSpace mt-2">
      <b-card>
        <div
          v-for="(item, index) in terms"
          :key="index"
          class="rowSpace"
        >
          <div class="termText">
            {{ item.title }}
          </div>
          <div class="termExplain">
            {{ item.subtitle }}
          </div>
          <div
            v-for="(p, q) in item.explain"
            :key="q"
            class="termExplain"
          >
            <div
              v-if="p.type === 1"
              class=""
            >
              {{ p.explain }}
            </div>
            <div
              v-if="p.type === 2"
              class=""
            >
              {{ p.explain }}
            </div>
            <div
              v-if="p.type === 3"
              class=""
            >
              <div class="">
                部署名：株式会社美クリエーション 個人情報保護管理担当宛
              </div>
              <div class="">
                電話：075-211-2473
              </div>
              <div class="">
                メール：info@be-creation.jp
              </div>
            </div>
          </div>
        </div>
        <div class="termExplain rowSpace">
          <div class="">
            株式会社美クリエーション
          </div>
          <div class="">
            代表取締役 髙木智加子
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BImg, BCard,
} from 'bootstrap-vue'
/* eslint-disable */
import terms from '@/components/basic/term/プライバシーポリシーについて.json'
/* eslint-enable */

export default {
  components: {
    BImg,
    BCard,
  },
  directives: {
  },
  props: {
  },
  data() {
    return {
      terms,
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.imgStyle_ {
  width: 110%;
  height: 100px;
  object-fit: cover;
  margin: -5% -5% 0 -5%;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .imgStyle_ {
    width: 110%;
    height: 360px;
    object-fit: cover;
    margin: -5% -5% 0 -5%;
  }
}
.title_ {
  font-size: 1.3rem;
  font-weight: 600;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .title_ {
    font-size: 2.2rem;
    font-weight: 600;
  }
}
.termText {
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0 0 2% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termText {
    font-size: 1.1rem;
    font-weight: 600;
    margin: 2% 0 3% 0;
  }
}
.termExplain {
  font-size: 0.8rem;
  margin: 1% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termExplain {
    font-size: 1rem;
    margin: 3% 0;
  }
}
.termsSpace {
  width: 95%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .termsSpace {
    width: 60%;
    margin: 0% auto;
  }
}
.rowSpace {
  margin: 3% 0;
  padding: 0 2%;
}
.underLine {
  border-bottom: solid 0.5px #e6e6e6;
  width: 100%;
  margin: 2% 0 0 0;
}
.explain {
  margin: 1% 0;
}
</style>
